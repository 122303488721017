import styled from "styled-components";


export const BreadWrapper = styled.div`
  position: relative;
  min-height: 150px;
  background: #000;
  color: #fff;
  text-align: center;
  line-height: 150px;
  
  .bread_wrap {
    width: ${props => props.theme.width.layoutWidth};
    margin: auto;
  }

  .content_name {
    font-size: 28px;
    font-weight: 500;
  }

  .bread {
    position: absolute;
    bottom: 0;
    background-color: #fff;
    padding: 0 20px;
    .ant-breadcrumb {
      color: #333;
      line-height: 2;
      .ant-breadcrumb-separator, a, .ant-breadcrumb-link {
        color: #333;
      }
    }
  }
`