import React, { memo } from 'react'
import { ContactWrapper } from "./style"
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4245150_wcf5kxgelkc.js',
});

const FooterContact = memo(() => {
  return (
    <ContactWrapper>
      <div className="item foot_logo_box">
        <img src={require("@/assets/image/flogo.png")} alt="" />
        <p className="logo_decs">We specialized in R & D and sales of aluminum plate-bar heat exchanger & automobile engine cooling system products.</p>
        <div className="follow_us">
          <p>Follow Us:</p>
          <ul className="foot_iconfonts">
            <li><IconFont type="icon-facebook" /></li>
            <li><IconFont type="icon-tuite" /></li>
            <li><IconFont type="icon-linkedin" /></li>
            <li><IconFont type="icon-instagram" /></li>
            <li><IconFont type="icon-youtube" /></li>
          </ul>
        </div>
      </div>
      <div className="item foot_contact_box">
        <p className="footer_title">CONTACT US</p>
        <ul className="contact_ul">
          <li><i><IconFont type="icon-xinfeng" /></i><span>sales003@siri-heatexchanger.com</span></li>
          <li><i><IconFont type="icon-phone" /></i><span>+86-20-84784941</span></li>
          <li><i><IconFont type="icon-dingwei" /></i><span>210, 7th Building No.29 Chang Sha Road, Da Long Street, Panyu District, Guangzhou, Guagdong, China.</span></li>
        </ul>
      </div>
      <div className="item foot_cate_box">
        <p className="footer_title">CATEGORIES</p>
        <ul className="contact_ul">
          <li className="categories_li"><i><IconFont type="icon-youjiantou" /></i><span>Radiator</span></li>
          <li className="categories_li"><i><IconFont type="icon-youjiantou" /></i><span>Oil Cooler</span></li>
          <li className="categories_li"><i><IconFont type="icon-youjiantou" /></i><span>Condenser</span></li>
        </ul>
      </div>
      <div className="item foot_news_box">
        <p className="footer_title">NEWS LETTER</p>
        <div className="send_letter">
          <p>Sign up to receive the latese information from us</p>
          <input type="text" name="" id="" placeholder='Enter Your Email' />
          <div className="subscribe_btn">SUBSCRIBE <i><IconFont type="icon-emizhifeiji" /></i></div>
        </div>
      </div>
    </ContactWrapper>
  )
})

export default FooterContact