import React, { memo, useEffect } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import routes from './router'
import RootHeader from './components/root-header'
import RootFooter from './components/root-footer'
import RootBread from './components/root-breadcrumb'

const App = memo(() => {
  // 解决跳转新页面滚动条不在顶部的问题
  const location = useLocation()
  useEffect(() => {
    if (document && location.pathname !== '/home') {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    }
  }, [location.pathname]);
  return (
    <div>
      <RootHeader/>
      <RootBread/>
      {
        useRoutes(routes)
      }
      <RootFooter/>
    </div>
  )
})

export default App