// import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Breadcrumb } from 'antd';

import { BreadWrapper } from './style'
import { transformPath } from '@/utils'; 

const RootBread = memo((props) => {
  const location = useLocation()
  const pathObj = transformPath(location.pathname)
  return (
    <>
    {
      pathObj.name !== "HOME" && (
        <BreadWrapper>
          <div className="bread_wrap">
            <div className="content_name">{pathObj.name}</div>
            <div className="bread">
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: 'Home',
                  },
                  {
                    title: pathObj.bread
                  }
                ]}
              />
            </div>
          </div>
        </BreadWrapper>
      )
    }
    </>
  )
})

RootBread.propTypes = {}

export default RootBread