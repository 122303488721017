import React, { memo, useState } from 'react'
import { Affix, Menu } from 'antd';

import { menus } from './data';
import { useNavigate } from 'react-router-dom';
import { HeaderMenuWrapper } from './style';

const HeaderNav = memo(() => {
  const [current, setCurrent] = useState("home")

  const navItems = menus
  const navigate = useNavigate()

  const navClickHandle = (e) => {
    // console.log(e);
    setCurrent(e.key)
    if(e.keyPath.length === 1) {
      navigate(e.key)
    } else if(e.keyPath.includes("products")) {
      navigate("products", { state: { paramsKey: e.key, parentKey: e.keyPath[1]} })
    }
  }

  return (
    <Affix offsetTop="0">
      <HeaderMenuWrapper>
        <div className="header_menu">
          <div className="head_logo">
            <img src={require("@/assets/image/logo.png")} alt="" />
          </div>
          <div className="head_nav">
            <Menu 
              style={{ flex: "auto", minWidth: 0, justifyContent: "flex-end" }} 
              selectedKeys={[current]} 
              mode="horizontal" 
              onClick={navClickHandle}
              items={navItems}
            />
          </div>
          <div className="head_contact">
              <img src={require("@/assets/image/cont.png")} alt=""/>
              <div className="cont_right">
                <p>Have any Questions?</p>
                <p className="phone">+86-20-84784941</p>
              </div>
          </div>
        </div>
      </HeaderMenuWrapper>
    </Affix>
  )
})

export default HeaderNav