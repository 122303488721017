const theme = {
  color: {
    bgColor1: "#2e2e2e",
    bgColor2: "#0078d7",
    bgColor3: "#f4f4f4",
    bgColor4: "#20232c",
  },
  text: {
    primaryColor: "#0078d7"
  },
  zIndex: {
    banner: 5
  },
  width: {
    bodyWidth: "1920px",
    navWidth: "1600px",
    layoutWidth: "1600px",
    layoutWidth2: "1400px",
  }
}

export default theme