// format path
export function transformPath(input) {
  // Remove the leading '/'
  const name = input.substring(1);

  // Replace '-' with ' ' and convert to uppercase
  const formattedName = name.replace(/-/g, ' ').toUpperCase();

  // Capitalize the first letter of the 'bread' field
  const formattedBread = name.replace(/-/g, ' ').toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());

  return { name: formattedName, bread: formattedBread };
}

export function capitalizeWords(string) {
  // 确保输入的是字符串，避免错误
  if (typeof string !== 'string') {
    return string;
  }
  
  return string
    .split(' ')  // 将字符串分割为单词
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())  // 对每个单词进行首字母大写操作
    .join(' ');  // 重新组合单词为字符串
}