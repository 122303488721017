import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { CopyWrapper } from "./style.js"

const FooterCopy = memo(() => {
  const pages = [
    {
      path: "/home",
      name: "Home"
    },
    {
      path: "/about-us",
      name: "About Us"
    },
    {
      path: "/products",
      name: "Products"
    },
    {
      path: "/news",
      name: "News"
    },
    {
      path: "/Knowledge",
      name: "knowledge"
    },
    {
      path: "/contact-us",
      name: "Contact Us"
    },
    {
      path: "/feedback",
      name: "Feedback"
    },
    {
      path: "/showroom",
      name: "Showroom"
    },
    {
      path: "/sitemap",
      name: "Sitemap"
    }
  ]

  const navigate = useNavigate()
  function toPageHandle(path) {
    navigate(path)
  }
  
  return (
    <CopyWrapper>
      <div className="copy_wrap">
        <div className="copy_right_text">
          Copyright © Guang Dong Siri Heat Exchanger Engin Cooling Co., Ltd. All Rights Reserved.
        </div>
        <ul className="page_link">
          {
            pages.map((item) => {
              return (
                <li key={item.name} onClick={e => toPageHandle(item.path)}>{item.name}</li>
              )
            })
          }
        </ul>
      </div>
    </CopyWrapper>
  )
})

export default FooterCopy