// import { Link } from "react-router-dom"

export const menus = [
  {
    label: "HOME",
    key: "home"
  },
  {
    label: "ABOUT US",
    key: "about-us"
  },
  {
    label: "PRODUCTS",
    // label: (<Link to="/products">PRODUCTS</Link>),
    key: "products",
    children: [
      {
        label: "RADIATOR",
        // label: (<Link to="/products?key=radiator">RADIATOR</Link>),
        key: "radiator",
        children: [
          {
            "label": "SUMITOMO RADIATOR",
            "key": "01-SUMITOMO RADIATOR"
          },
          {
            "label": "KATO RADIATOR",
            "key": "02-KATO RADIATOR"
          },
          {
            "label": "CASE RADIATOR",
            "key": "03-CASE RADIATOR"
          },
          {
            "label": "TOYOTA RADIATOR",
            "key": "04-TOYOTA RADIATOR"
          },
          {
            "label": "NISSAN RADIATOR",
            "key": "05-NISSAN RADIATOR"
          },
          {
            "label": "KOMATSU RADIATOR",
            "key": "06-KOMATSU RADIATOR"
          },
          {
            "label": "HITACHI RADIATOR",
            "key": "07-HITACHI RADIATOR"
          },
          {
            "label": "KOBELCO RADIATOR",
            "key": "08-KOBELCO RADIATOR"
          },
          {
            "label": "DOOSAN RADIATOR",
            "key": "09-DOOSAN RADIATOR"
          },
          {
            "label": "HYUNDAI RADIATOR",
            "key": "10-HYUNDAI RADIATOR"
          },
          {
            "label": "CAT RADIATOR",
            "key": "11-CAT RADIATOR"
          },
          {
            "label": "VOLVO RADIATOR",
            "key": "12-VOLVO RADIATOR"
          },
          {
            "label": "JCB RADIATOR",
            "key": "13-JCB RADIATOR"
          },
          {
            "label": "KENWORTH RADIATOR",
            "key": "14-KENWORTH RADIATOR"
          },
          {
            "label": "FREIGHTLINER RADIATOR",
            "key": "15-FREIGHTLINER RADIATOR"
          },
          {
            "label": "INTERNATIONAL RADIATOR",
            "key": "16-INTERNATIONAL RADIATOR"
          },
          {
            "label": "GMC FORD RADIATOR",
            "key": "17-GMC FORD RADIATOR"
          },
          {
            "label": "DAF RADIATOR",
            "key": "18-DAF RADIATOR"
          },
          {
            "label": "IVECO RADIATOR",
            "key": "19-IVECO RADIATOR"
          },
          {
            "label": "MAN RADIATOR",
            "key": "20-MAN RADIATOR"
          },
          {
            "label": "MERCEDES BENZ RADIATOR",
            "key": "21-MERCEDES BENZ RADIATOR"
          },
          {
            "label": "MITSUBISHI RADIATOR",
            "key": "22-MITSUBISHI RADIATOR"
          },
          {
            "label": "RENAULT RADIATOR",
            "key": "23-RENAULT RADIATOR"
          },
          {
            "label": "SCANIA RADIATOR",
            "key": "24-SCANIA RADIATOR"
          },
          {
            "label": "HINO RADIATOR",
            "key": "25-HINO RADIATOR"
          },
          {
            "label": "AUDI RADIATOR",
            "key": "26-AUDI RADIATOR"
          },
          {
            "label": "BMW RADIATOR",
            "key": "27-BMW RADIATOR"
          },
          {
            "label": "DAEWOO RADIATOR",
            "key": "28-DAEWOO RADIATOR"
          },
          {
            "label": "DAIHATSU RADIATOR",
            "key": "29-DAIHATSU RADIATOR"
          },
          {
            "label": "DODGE RADIATOR",
            "key": "30-DODGE RADIATOR"
          },
          {
            "label": "FIAT RADIATOR",
            "key": "31-FIAT RADIATOR"
          },
          {
            "label": "GENERATOR RADIATOR",
            "key": "32-GENERATOR RADIATOR"
          },
          {
            "label": "HOLDEN RADIATOR",
            "key": "33-HOLDEN RADIATOR"
          },
          {
            "label": "HONDA RADIATOR",
            "key": "34-HONDA RADIATOR"
          },
          {
            "label": "ISUZU RADIATOR",
            "key": "35-ISUZU RADIATOR"
          },
          {
            "label": "KIA RADIATOR",
            "key": "36-KIA RADIATOR"
          },
          {
            "label": "LADA RADIATOR",
            "key": "37-LADA RADIATOR"
          },
          {
            "label": "LAND ROVER RADIATOR",
            "key": "38-LAND ROVER RADIATOR"
          },
          {
            "label": "MAZDA RADIATOR",
            "key": "39-MAZDA RADIATOR"
          },
          {
            "label": "OPEL RADIATOR",
            "key": "40-OPEL RADIATOR"
          },
          {
            "label": "PEUGEOT RADIATOR",
            "key": "41-PEUGEOT RADIATOR"
          },
          {
            "label": "PORSCHE RADIATOR",
            "key": "42-PORSCHE RADIATOR"
          },
          {
            "label": "SAAB RADIATOR",
            "key": "43-SAAB RADIATOR"
          },
          {
            "label": "SSANGYONG RADIATOR",
            "key": "44-SSANGYONG RADIATOR"
          },
          {
            "label": "SUBARU RADIATOR",
            "key": "45-SUBARU RADIATOR"
          },
          {
            "label": "SUZUKI RADIATOR",
            "key": "46-SUZUKI RADIATOR"
          },
          {
            "label": "VOLKSWAGEN RADIATOR",
            "key": "47-VOLKSWAGEN RADIATOR"
          }
        ]
      },
      {
        label: "OIL COOLER",
        // label: (<Link to="/products?key=oilcooler">OIL COOLER</Link>),
        key: "oilcooler",
        children: [
          {
            "label": "SUMITOMO OIL COOLER",
            "key": "01-SUMITOMO OIL COOLER"
          },
          {
            "label": "KATO OIL COOLER",
            "key": "02-KATO OIL COOLER"
          },
          {
            "label": "KOMATSU OIL COOLER",
            "key": "03-KOMATSU OIL COOLER"
          },
          {
            "label": "HITACHI OIL COOLER",
            "key": "04-HITACHI OIL COOLER"
          },
          {
            "label": "KOBELCO OIL COOLER",
            "key": "05-KOBELCO OIL COOLER"
          },
          {
            "label": "DOOSAN OIL COOLER",
            "key": "06-DOOSAN OIL COOLER"
          },
          {
            "label": "HYUNDAI OIL COOLER",
            "key": "07-HYUNDAI OIL COOLER"
          },
          {
            "label": "CAT OIL COOLER",
            "key": "08-CAT OIL COOLER"
          },
          {
            "label": "VOLVO OIL COOLER",
            "key": "09-VOLVO OIL COOLER"
          },
          {
            "label": "JCB OIL COOLER",
            "key": "10-JCB OIL COOLER"
          }
        ]
      },
      {
        label: "CONDENSER",
        // label: (<Link to="/products?key=condenser">CONDENSER</Link>),
        key: "condenser",
        children: [
          {
            label: "BWM CONDENSER",
            key: "01-BWM CONDENSER"
          },
          {
            label: "BENZ CONDENSER",
            key: "02-BENZ CONDENSER"
          }
        ]
      }
    ]
  },
  {
    label: "NEWS",
    key: "news"
  },
  {
    label: "KNOWLEDGE",
    key: "knowledge"
  },
  {
    label: "CONTACT US",
    key: "contact-us"
  },
  {
    label: "FEEDBACK",
    key: "feedback"
  }
]