import React, { memo } from 'react'
import {HeaderWrapper} from "./style"
import HeaderTop from './c-cpns/header-top';
import HeaderNav from './c-cpns/header-nav';

const RootHeader = memo(() => {
  return (
    <HeaderWrapper>
      <HeaderTop/>
      <HeaderNav/>
    </HeaderWrapper>
  )
})

export default RootHeader