import { createSlice } from "@reduxjs/toolkit"

import Radiator from "@/pages/products/data/radiator"
import OilCooler from "@/pages/products/data/oilcooler"
import Condenser from "@/pages/products/data/condenser"

const totalObject = {...Radiator, ...OilCooler, ...Condenser}
const totalData = []
for(let key in totalObject) {
  totalData.push(...totalObject[key][0].list)
}
// product -> radiator/oil cooper/condenser -> submitomo radiator ...
const productSlice = createSlice({
  name: "products",
  initialState: {
    radiator: Radiator,
    oilCooler: OilCooler,
    condenser: Condenser,
    currentList: [],
    totalData: totalData
  },
  reducers: {
    getDataListAction(state, { payload }) {
      const { paramsKey, parentKey } = payload
      let list = []
      switch(parentKey) {
        case "radiator": 
              list = state.radiator[paramsKey][0].list;break;
        case "oilcooler": 
              list = state.oilCooler[paramsKey][0].list;break;
        case "condenser": 
              list = state.condenser[paramsKey][0].list;break;
        default: break;
      }
      state.currentList = list
      // console.log(paramsKey,parentKey, list);
    },
    getSearchListAction(state, { payload }) {
      // 搜索
      // console.log(payload);
      state.currentList = state.totalData.filter(item => item.title.indexOf(payload) > -1)
    }
  }
})

export const { getDataListAction, getSearchListAction } = productSlice.actions

export default productSlice.reducer
