import styled from "styled-components";

export const HeaderMenuWrapper = styled.div`
  background: #fff;
  .header_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    position: sticky;
    top: 0;
    width: ${props => props.theme.width.layoutWidth};
    margin: auto;
    

    .head_logo {
      height: 100%;
      img {
        height: 100%;
      }
    }

    .head_nav {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      
      .ant-menu-horizontal {
        border: none;
        font-weight: 500;
        font-size: 16px;
        color: #333;
        ::-webkit-scrollbar {
          width: 0;
        }
        .ant-menu-overflow-item {
          &:hover {
            color: ${props => props.theme.text.primaryColor};
          }
        }
        .ant-menu-overflow-item::after {
          border: none;
        }

        .ant-menu-item-selected {
          color: ${props => props.theme.text.primaryColor};
          
          &::after {
            border: none;
          }
        }
      }
    }

    .head_contact {
      display: flex;
      align-items: center;
      margin-left: 40px;

      img {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }

      .cont_right {
        font-size: 12px;
        font-weight: 500;

        .phone {
          font-size: 16px;
          font-weight: 300;
          margin-top: 10px;
        }
      }
    }
  }
`