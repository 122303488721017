import React, { memo } from 'react'
import { createFromIconfontCN } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4245150_8saxr3z7qyh.js',
});

const HeaderTop = memo(() => {
  return (
    <div className="header_top"> 
      <div className="top_left">
        <p className="top_link"><IconFont type="icon-xinfeng" /><a href='mailto:sales003@siri-heatexchanger.com'>sales003@siri-heatexchanger.com</a></p>
        <p className="top_link"><IconFont type="icon-phone" /><span>+86-20-84784941</span></p>
      </div>
      <div className="top_right">
        <div className="search_icon"><SearchOutlined /></div>
      </div>
    </div>
  )
})

export default HeaderTop