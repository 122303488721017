import { styled } from "styled-components";

export const CopyWrapper = styled.div`
    border-top: 1px solid #44464e;
    .copy_wrap {
      display: flex;
      height: 60px;
      font-size: 14px;
      justify-content: space-between;
      align-items: center;
      color: #d1d1d1;
      width: ${props => props.theme.width.layoutWidth};
      margin: auto;
    }
    .page_link {
      display: flex;
      cursor: pointer;

      li {
        margin: 0 10px;
        &:hover {
          color: #fff;
        }
      }
    }
`