import React from "react"
import { Navigate } from "react-router-dom"
const Home = React.lazy(() => import("@/pages/home"))
const About = React.lazy(() => import("@/pages/about"))
const Products = React.lazy(() => import("@/pages/products"))
const ProductContent = React.lazy(() => import("@/pages/products/content"))
const News = React.lazy(() => import("@/pages/news"))
const NewsContent = React.lazy(() => import("@/pages/news/content"))
const Knowledge = React.lazy(() => import("@/pages/knowledge"))
const Contact = React.lazy(() => import("@/pages/contact"))
const Feedback = React.lazy(() => import("@/pages/feedback"))
const NotFound = React.lazy(() => import("@/pages/notfound"))

const routes = [
  {
    path: "/",
    element: <Navigate to="/home"/>
  },
  {
    path: "/home",
    element: <Home/>
  },
  {
    path: "/about-us",
    element: <About/>
  },
  {
    path: "/products",
    element: <Products/>
  },
  {
    path: "/content",
    element: <ProductContent/>
  },
  {
    path: "/news",
    element: <News/>
  },
  {
    path: "/contents",
    element: <NewsContent/>
  },
  {
    path: "/knowledge",
    element: <Knowledge/>
  },
  {
    path: "/contact-us",
    element: <Contact/>
  },
  {
    path: "/feedback",
    element: <Feedback/>
  },
  {
    path: "*",
    element: <NotFound/>
  }
]


export default routes