import { styled } from "styled-components";


export const ContactWrapper = styled.div`
    @keyframes scaleAnimation {
        0%, 100% { transform: scale(1); }
        50% { transform: scale(1.1); }
    }
    padding: 50px 0;
    display: flex;
    justify-content: space-around;
    width: ${props => props.theme.width.layoutWidth};
    margin: auto;

    >.item {
      width: 25%;
    }

    .foot_logo_box {
      img {
        max-width: 100%;
      }
      .logo_decs {
        margin: 30px 0;
      }
      .follow_us {
        font-size: 16px;

        .foot_iconfonts {
          display: flex;
          margin-top: 20px;

          li {
            width: 40px;
            height: 40px;
            background: ${props => props.theme.color.bgColor2};
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            transition: all 700ms;
            cursor: pointer;

            &:hover {
              animation: scaleAnimation 700ms infinite;
            }
          }
        }
      }
    }

    .foot_contact_box {
      padding-left: 100px;
    }
    .foot_cate_box {
      padding-left: 100px;
    }
    .foot_news_box {
      .send_letter {
        margin-top: 30px;
        >p:nth-child(1) {
          width: 250px;
          line-height: 2;
        }

        input {
          height: 45px;
          background: #20232c;
          border: 1px solid #505050;
          box-sizing: border-box;
          width: 280px;
          padding-left: 14px;
          margin: 20px 0;
          transition: outline 250ms ease;
          &:focus {
            outline: 1px solid ${props => props.theme.color.bgColor2};
          }
        }
        .subscribe_btn {
          width: 150px;
          height: 40px;
          background: ${props => props.theme.color.bgColor2};
          box-sizing: border-box;
          color: #FFF;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          i {
            font-size: 18px;
            margin-left: 14px;
          }
        }
      }
    }

    .contact_ul {
      margin-top: 30px;
      li {
        margin: 10px 0;

        i {
          color: ${props => props.theme.text.primaryColor};
          margin-right: 14px;
        }
      }
      .categories_li {
        i {
          color: #fff;
          font-size: 10px;
        }
        transition: all ease 250ms;
        cursor: pointer;
        &:hover {
          transform: translateX(10px);
        }
      }
    }


    .footer_title {
      font-size: 20px;
      font-weight: 600;
      color: #F3F3F3;
      line-height: 1.5;
      text-transform: uppercase;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 54px;
        height: 3px;
        background: ${props => props.theme.color.bgColor2};
        top: 120%;
        display: block;
        left: 0;
      }
    }
`