import React, { memo } from 'react'
import {FootWrapper} from "./style"
import FooterCopy from './c-cpns/footer-copy'
import FooterContact from './c-cpns/footer-contact'

const RootFooter = memo(() => {
  return (
    <FootWrapper>
      <FooterContact/>
      <FooterCopy/>
    </FootWrapper>
  )
})

export default RootFooter