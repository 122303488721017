import { styled } from "styled-components";


export const HeaderWrapper = styled.div`
  background: ${props => props.theme.color.bgColor1};
  
  .header_top {
    width: ${props => props.theme.width.layoutWidth};
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    color: #fff;

    .top_left {
      display: flex;
      font-size: 14px;
      .top_link {
        margin-right: 15px;
        a {
          color: #fff;
          text-decoration: none;
          cursor: pointer;
          font-weight: 300;
        }
        span {
          margin-right: 5px;
          color: #d5d5d5;
        }
      }
    }
  }


`